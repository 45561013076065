.footer_color{
    background-color:#6b778d;
    width: 100%;
    color: white;
    padding: 20px;
    height: 100%;
    text-align: center;

    
}

@media(max-width: 768px){

    .footer_color{
        display: none;
      
      
    }

   
}
