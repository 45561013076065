.main{
    overflow: hidden;
}
.swiper{
    width: 100%;
    height: 510px;
    min-height: 100%;
}
.footer{
    background-color:#6b778d;
    position: fixed;
    z-index: 9999;
    left: 0;
    bottom: 0;
    width: 100%;
    color: white;
    padding: 20px;
    
}
.news-title{
    text-align: center;


}
.news-main{
    display:flex;
    flex-direction: row;
    flex-wrap: wrap;
   
    justify-content: center;    
  /* 垂直置中 */
    align-items: center;  
    width: 100%;
    height: 100%;

}
.news-image-list{
    text-align: center;
    padding: 20px;
    background-color: #ffffff;

    
}
.news-image-list-img{
    width: 300px;
    height: 200px;
}
.news-image-list-btn{
    border-radius: 20px;
    width: 150px;
    height: 40px;
    background-color: #348498;
    border-color: #348498;
    font-size: 14px;

}
.news-image-list-a{
    text-decoration:none;
    color: #ffffff;
  
}

.about-flex{
    display: flex;



}
.about{
    padding: 50px;
}
.about-list-title-Rwd{
    display:none;
}
.about-list-txt{
    text-indent: '2em';
    padding: 20px;
    font-size: 20px;
    background-color: #ccc;
    margin-right: 20px;
    border-radius: 20px;
  

}
.about-btn{
    border-radius: 20px;
    width: 200px;
    height: 50px;
    background-color: #348498;
    color: #ffffff;
    border-color: #348498;
    font-size: 20px;

   
}
.about-btn:hover{
    border-radius: 20px;
    width: 200px;
    height: 50px;
    background-color: #ffffff;
    color: #000000;
    border-color: #ffffff;
    font-size: 20px;

}


@media(max-width: 768px){
    .swiper{
        width: 100%;
        height: 100%;
      
    }
    .news-image-list{
        margin-left:-10px;
    }
    .news-image-list-img{
      width: 150px;
      height: 100px;
   }
   .news-image-list-txt{
    width: 100px;
    text-overflow: ellipsis;
   }
   .news-image-list-btn{
    border-radius: 10px;
    width: 100px;
    height: 25px;
    background-color: #348498;
    color: #ffffff;
    border-color: #348498;
    font-size: 14px;
}
   .about{
    display: none;
   }
    .about-list{
        text-align: center;
    }
    .about-list-txt{
        display: none;
    }
    .about-flex{

        flex-wrap: wrap;
    }
    .about-right-img{
        margin-top: 10px;
        width: 100%;
        height: 100%;

    }
}
