.performanceMain{
    display: flex;
    flex-direction: row ;
    flex-wrap: wrap ;
    overflow: hidden;
    justify-content: center;    
  /* 垂直置中 */
    align-items: center;  
    
    max-width: 100%;
    height: 100%;
}



  
.image-List{
  width: 95%;

}
.img-List-2-main{
    text-align: center;

}

@media(min-width: 768px){
    .image-List-2{
        display: none;
    }

   
   
}

@media(max-width: 768px){
   
    .image-List{
        display:none;
    }
    .performanceMain {
       width: 400px;
       margin: 10px;
      
    }
   
}

