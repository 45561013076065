.left-menu{
    padding: 20px;
}
.about-article{
  padding:25px;

}

.about-article.p{
  text-indent: 1em;
  line-height: 1.5;
  text-indent: 32px;
}
.about-organize{
  text-align: center;
  font-size: 25px;


}
.about-organize-img{
  width: 600px;
  height: 700px;
}
/* h1{
    
        font-family: monospace;
        border-right: 4px solid;
        width: 7ch;
        white-space: nowrap;
        overflow: hidden;
        animation: typing 2s steps(10), blinking 0.5s infinite step-end alternate;
      
      
}

@keyframes typing {
    from {
      width: 0;
    }
  }
  
  @keyframes blinking {
    50% {
      border-color: transparent;
    }
  } */

  @media(max-width: 768px){
   .about-organize-img{
    width: 100%;
    height: 100%;
   }
}

  