.TopicButton{
    width: 200px;
    font-size: 25px;
    background-color: #0B2447;
    border-color: #0B2447;
    color: #ffffff;
    border-radius: 10px;
    margin-left: 10px;
    margin-top: 10px;
                                                                                                  
  } 